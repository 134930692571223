import React, { useState } from "react";
import {
  analyzePlaces,
  getPlacesByFlags as getPlaces,
  getSummary,
} from "../../../api/googleBusinessProfileApi";
import translate from "../../../config/translations/translate";
import "./styles.css";
import { useSelector } from "react-redux";
import { FirstConfigPageContainer } from "./FirstConfigPage";
import { LoadingPageContainer } from "./LoadingPage";
import { ErrorPageContainer } from "./ErrorPage";
import {
  Table,
  UxMetricDisplay,
  UxScoreBar,
  UxCallToActionImproveImpactList,
  Filters,
} from "botbit-ui-components";
import "bootstrap/dist/js/bootstrap.bundle";
import withEventTracking, { trackers } from "../../../config/withEventTracking";
import TimeAgo from "../../../commons/components/utils/TimeAgo";
import { getProcessStatus } from "../../../api/processApi";
import ScoreScreen from "./ScoreDescription/ScoreScreen";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PostModalContainer from "./details/PostModal/PostModalContainer";

const buildCaption = (summary, t ) => ({
  caption: {
    key: "caption-main", 
    title: t("summary.captionTitle"),
    elements: [
      {
        key: "keywords-element",
        icon: "tag",
        value: summary.placesWithoutKeywords,
        description: t("summary.placesWithoutKeywords"),
        highlighted: {
          text: "string",
          color: "danger",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        key: "posts-element",
        icon: "sticky-note",
        value: summary.placesWithoutPosts,
        description: (
          <div key="posts-description">
            {t("summary.placesWithoutPosts")}
            <br />
            {t("summary.last15")}
          </div>
        ),
        highlighted: {
          text: "string",
          color: "danger",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        key: "photos-element",
        icon: "camera",
        value: summary.placesWithoutPhotos,
        description: t("summary.placesWithoutPhotos"),
        highlighted: {
          text: "string",
          color: "danger",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        key: "phone-element",
        icon: "phone-alt",
        value: summary.placesWithoutPhone,
        description: t("summary.placesWithoutPhone"),
        highlighted: {
          text: "string",
          color: "danger",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
      {
        key: "website-element",
        icon: "globe",
        value: summary.placesWithoutWebsite,
        description: t("summary.placesWithoutWebsite"),
        highlighted: {
          text: "string",
          color: "danger",
        },
        totalCount: `/${summary.numberOfPlaces}`,
      },
    ],
  },
});

const metricsConfiguration = (summary, t, openModal, openPostModal) => ({
  key: "metrics-config",
  outstanding: {
    key: "outstanding-section",
    icon: "",
    content: "React.Component",
    value: summary.score || 0,
    title: t("summary.currentScore"),
    showBar: true,
  },
  caption: {
    key: "caption-section",
    ...buildCaption(summary, t, openModal, openPostModal).caption,
  },
  scoreInfoLink: {
    key: "score-info-section",
    text: t('score.howItWorks'),
    onclickHandler: openModal,
  },
});

const BusinessProfilesTable = ({ t, trackEvent }) => {
  const history = useHistory();
  const selectedCompany = useSelector(
    (state) => state.application.selectedCompany
  );

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [summary, setSummary] = useState();
  const [isOpenn, setIsOpenn] = useState(false);

  const [lastAnalysisDate, setLastAnalysisDate] = useState();

  const [filterValues, setFilterValues] = useState({
    combinedFilter: [],
  });

  const filtersConfig = [
    { id: "withoutKeywords", label: t("withoutKeywords") },
    { id: "withoutPosts", label: t("withoutPosts") },
    { id: "withoutPhotos", label: t("withoutPhotos") },
    { id: "withoutPhone", label: t("withoutPhone") },
    { id: "withoutWebsite", label: t("withoutWebsite") },
  ];

  const schemas = {
    combinedFilter: {
      id: "combinedFilter",
      label: t("filterBy"),
      type: "multiselect",
      options: filtersConfig.map((filter) => ({
        value: filter.id,
        text: filter.label,
      })),
      defaultValues: [],
      visible: true,
      priority: true,
    },
  };

  const handleFilterChange = (newValues) => {
    setFilterValues((prev) => {
      return {
        ...prev,
        ...newValues
      };
    });
  };

  const columns = [
    {
      dataField: "score",
      title: t("score"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => (
        <UxScoreBar value={cellContent} showIcon={true} />
      ),
      size: 15,
    },
    {
      dataField: "name",
      title: t("titleAndVerification"),
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => (
        <div className="mt-0">
          <span className="font-bold text-black fs-14">{cellContent}</span>{" "}
          <br />
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.65619 0.945559C6.84902 0.762939 7.15098 0.762939 7.34381 0.945559C8.68832 2.21887 10.5025 2.99909 12.5 2.99909C12.5318 2.99909 12.5635 2.9989 12.5952 2.9985C12.8134 2.99579 13.0082 3.13496 13.0764 3.3423C13.3514 4.17881 13.5 5.07211 13.5 5.99912C13.5 9.96047 10.7906 13.2877 7.12463 14.2313C7.04288 14.2523 6.95713 14.2523 6.87537 14.2313C3.20937 13.2877 0.5 9.96047 0.5 5.99912C0.5 5.07211 0.648582 4.17881 0.92361 3.3423C0.99178 3.13496 1.18656 2.99579 1.40481 2.9985C1.43649 2.9989 1.46822 2.99909 1.5 2.99909C3.4975 2.99909 5.31168 2.21887 6.65619 0.945559ZM7 4.99908C7.27614 4.99908 7.5 5.22294 7.5 5.49908V7.99908C7.5 8.27523 7.27614 8.49908 7 8.49908C6.72386 8.49908 6.5 8.27523 6.5 7.99908V5.49908C6.5 5.22294 6.72386 4.99908 7 4.99908ZM7 9.49908C6.72386 9.49908 6.5 9.72294 6.5 9.99908V10.0041C6.5 10.2802 6.72386 10.5041 7 10.5041H7.005C7.28114 10.5041 7.505 10.2802 7.505 10.0041V9.99908C7.505 9.72294 7.28114 9.49908 7.005 9.49908H7Z"
              fill={source.verified ? "#1EB8FF" : "gray"}
            />
          </svg>
          <span
            className={"ml-1 " + (source.verified ? "text-blue" : "text-gray")}
            style={{ fontWeight: 700 }}
          >
            {source.verified ? t("verified") : t("notVerified")}
          </span>
        </div>
      ),
      size: 22,
    },
    {
      title: t("informationToUpdate"),
      dataField: "Informacion para ser actualizada",
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        // console.log("Source data:", source); 
        const items = [
          source.phoneValueIsOk ? null : (
            <span key="phoneValueIsOk" title={t("noPhone")}>
              {t("phone")}
            </span>
          ),
          source.websiteValueIsOk ? null : (
            <span key="website" title={t("noWebsite")}>
              {t("website")}
            </span>
          ),
          source.address ? null : (
            <span key="address" title={t("noAddress")}>
              {t("address")}
            </span>
          ),
        ].filter(Boolean);

        if (items.length === 0) {
          return <h6>{t("allUpdated")}</h6>;
        }

        return (
          <UxCallToActionImproveImpactList
            title={t("Atributos a mejorar: ")}
            items={items}
          />
        );
      },
      size: 27,
    },
    {
      title: t("improveContent"),
      dataField: "Contenido a mejorar",
      headerClassName: "text-uppercase",
      formatter: (cellContent, source) => {
        const items = [
          source.postHistoryCountValueIsOk ? null : (
            <span key="postHistoryCount" title={t("noPosts")}>
              {t("postHistoryCount")}
            </span>
          ),
          source.photosValueIsOk ? null : (
            <span key="photos" title={t("noPhoto")}>
              {t("photos")}
            </span>
          ),
          source.primaryCategoryMatched && source.primaryCategoryMatched.length > 0 || source.secondaryCategoryMatched && source.secondaryCategoryMatched.length > 0 ? null : (
            <span key="categories" title={t("noCategories")}>
              {t("categories")}
            </span>
          ),
        ].filter(Boolean);

        if (items.length === 0) {
          return <h6>{t("allUpdated")}</h6>;
        }

        return (
          <UxCallToActionImproveImpactList
            title={t("improvementOpportunities")}
            items={items}
          />
        );
      },
      size: 22,
    },
    {
      title: t("actionables"),
      dataField: "Accionables",
      headerClassName: "text-uppercase",

      formatter: (cellContent, source) => {

        const handleRedirect = () => {
          history.push(`/localseo/gbp/${source.id}/${source.socialLocationId}`);
        };

        return (
          <div className="button-container">
            <button className="table-button" onClick={handleRedirect}>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.90424 0.269187C1.95153 0.109594 2.09908 0 2.26667 0H5.28889C5.41032 0 5.52435 0.0579453 5.59536 0.155733C5.66636 0.253521 5.68568 0.379229 5.64728 0.493584L4.30192 4.49999H7.17777C7.32206 4.49999 7.45375 4.58157 7.51714 4.71023C7.58053 4.83889 7.56456 4.99216 7.47597 5.10522L2.18709 11.8552C2.07512 11.9981 1.8763 12.0412 1.71452 11.9576C1.55273 11.8741 1.47419 11.6877 1.52782 11.5147L2.88851 7.12498H0.377781C0.258722 7.12498 0.14663 7.06927 0.075303 6.97465C0.00397562 6.88002 -0.0182402 6.75755 0.015354 6.64417L1.90424 0.269187Z"
                  fill="white"
                />
              </svg>
              Ver Accionables
            </button>
          </div>
        );
      },
      size: 22,
    },
  ];

  const emmit = (action) =>
    trackEvent(action.toLowerCase(), {}, false, [
      trackers.BACKEND,
      trackers.MIXPANEL,
    ]);

  React.useEffect(() => {
    if (loading || firstTime) return;
    emmit("GBP_audit_viewed");
    getSummary(selectedCompany.id).then((res) => {
      if (res.data.data) {
        setSummary(res.data.data);
      }
    });
  }, [loading, firstTime]);

  const analyze = async () => {
    setFirstTime(false);
    setLoading(true);
    setSummary(undefined);

    try {
      const response = await analyzePlaces(selectedCompany.id);
      const processId = response.data.data.id;
      checkAnalyzeStatus(processId);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  const checkAnalyzeStatus = async (processId) => {
    try {
      const response = await getProcessStatus(processId);
      if (response.data.data) {
        const status = response.data.data.state;

        if (status === "FINISHED_OK") {
          setLoading(false);
        } else if (status === "RUNNING") {
          setTimeout(() => checkAnalyzeStatus(processId), 5000);
        } else {
          setError("Unexpected error: " + status);
        }
      } else {
        setError("Invalid response");
      }
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  if (error) {
    return <ErrorPageContainer error={error} />;
  } else if (firstTime) {
    return <FirstConfigPageContainer analyze={analyze} />;
  } else if (loading) {
    return <LoadingPageContainer />;
  } else {
    const getTableData = (page, order, filters) =>
      getPlaces(page, order, {
        companyId: selectedCompany.id,
        ...filters,
      }).then((res) => {
        // console.log("Table Data Source:", res.data.data);

        if (res.data.data) {
          if (!res.data.data.lastAnalysisDate) setFirstTime(true);
          else setLastAnalysisDate(res.data.data.lastAnalysisDate);
        }
        // console.log("response in get: ", res);
        return res;
      });
    return (
      <div className="gbp-container">
        <div className="title">
          <div className="title-text">
            <img
              src="/static/img/gbp-icon.svg"
              alt="Icon"
              width="44"
              height="44"
            />
            &nbsp;&nbsp;
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "28px",
                color: "1A1A1A",
              }}
            >
              {t("welcome")}
            </span>
            &nbsp;&nbsp;
            <span
              style={{
                fontFamily: "Open Sans",
                fontSize: "28px",
                color: "1A1A1A",
                fontWeight: "bold",
              }}
            >
              {t("title")}
            </span>
          </div>
          <div className="last-update">
            <span>
              {t("analyze")}
              <TimeAgo date={lastAnalysisDate}></TimeAgo>
            </span>
            {/* <MDBBtn
              size="sm"
              onClick={() => analyze()}
              style={{ width: "50px", padding: "10px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#ffffff"
                  d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"
                />
              </svg>
            </MDBBtn> */}
            <button onClick={() => analyze()} className="refresh-button">
              <svg
                width="16"
                height="16"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.84873C1.92525 4.24585 4.45215 2.16471 6.18404 1.51275C7.91592 0.860793 9.82024 0.829626 11.5725 1.42456C13.3248 2.01949 14.8166 3.20372 15.7938 4.77546C16.771 6.3472 17.1731 8.2092 16.9316 10.0442C16.69 11.8792 15.8198 13.5737 14.4692 14.8389C13.1186 16.1041 11.3712 16.8618 9.52463 16.9829C7.6781 17.1039 5.84673 16.5808 4.34256 15.5028C2.8384 14.4247 1.7545 12.8583 1.27556 11.0705M1 5.84873H5.84781M1 5.84873V1"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        {summary && (
          <>
            <div className="">
              <UxMetricDisplay
                data={metricsConfiguration(
                  summary,
                  t,
                  () => setIsOpenn(!isOpenn)
                  // openPostModal
                )}
              />
              <ScoreScreen
                isOpen={isOpenn}
                toggle={() => setIsOpenn(!isOpenn)}
              />
            </div>

            {/* <VideoModal
              description={t("summary.videoDescription")}
              title={t("summary.videoTitle")}
              subtitle={t("summary.videoSubtitle")}
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              url={"https://www.youtube.com/watch?v=Qx8ElTKfE-g"}
            />
            <PostModal
              isOpen={isPostModalOpen !== undefined}
              toggle={() => setIsPostModalOpen(undefined)}
              title={t("createPost")}
              socialLocationId={isPostModalOpen}
            /> */}
          </>
        )}
        <div className="filterContainer">
          <Filters
            schemas={schemas}
            values={filterValues}
            onChange={handleFilterChange}
          />
        </div>
        <Table
          pagination={false}
          getData={getTableData}
          filterValues={filterValues}
          title={t("title")}
          subTitle={<p>{t("subtitle")}</p>}
          columns={columns}
          showMobileColumnTitles={false}
        />
        <PostModalContainer />
      </div>
    );
  }
};

BusinessProfilesTable.translations = {
  welcome: {
    en: "Optimize your ",
    es: "Optimiza tus ",
  },
  title: {
    en: "Google Business Profiles",
    es: "Google Business Profiles",
  },
  subtitle: {
    en: "Welcome to our new Google Business Profiles report!",
    es: "¡Bienvenido a nuestro nuevo reporte de Perfiles de Google My Business!",
  },
  searchLabel: {
    en: "Search",
    es: "Buscar por nombre, provincia, país",
  },
  searchPlaceholder: {
    en: "Search location",
    es: "Buscar ubicación",
  },
  verifiedInfo: {
    en: "This profile is verified by Google",
    es: "Este perfil está verificado por Google",
  },
  website: {
    en: "Website",
    es: "Sitio Web"
  },
  noWebSite: {
    en: "No website",
    es: "Sin sitio web",
  },
  reviews: {
    en: "Rate",
    es: "Rate",
  },
  photos: {
    en: "photos",
    es: "fotos",
  },
  categories: {
    en: "Categories",
    es: "Categorías",
  },
  howToEliminateProfile: {
    en: "How to eliminate this profile",
    es: "Cómo eliminar este perfil",
  },
  noCategories: {
    en: "No categories",
    es: "Sin categorías",
  },
  noPhone: {
    en: "No phone",
    es: "Sin teléfono",
  },
  name: {
    en: "Title",
    es: "Título",
  },
  titleAndVerification: {
    en: "Title and verification",
    es: "Título y verificacion",
  },
  informationToUpdate: {
    es: "Información para ser actualizada",
    en: "Information to be updated",
  },
  improveContent: {
    es: "Contenido a mejorar",
    en: "Content to improve",
  },
  improvementOpportunities: {
    es: "Oportunidades de mejora: ",
    en: "Opportunities for improvement: ",
  },
  actionables: {
    es: "Accionables",
    en: "Actionables",
  },
  filterBy: {
    en: "Filter by: ",
    es: "Filtrar por: ",
  },
  allUpdated: {
    en: "Great job! This branch has everything up to date",
    es: "¡Gran trabajo! Esta sucursal tiene todo actualizado",
  },
  address: {
    en: "Address",
    es: "Dirección",
  },
  complete: {
    en: "Complete",
    es: "Completa",
  },
  noPhoto: {
    en: "No photo!",
    es: "¡No tienes fotos!",
  },
  duplicated: {
    en: "Duplicated",
    es: "Duplicado",
  },
  profileSelectLabel: {
    en: "Profiles",
    es: "Perfiles",
  },
  descriptionStatusLabel: {
    en: "Description",
    es: "Descripción",
  },
  incomplete: {
    en: "Incomplete",
    es: "Incompleta",
  },
  toExtensive: {
    en: "To extensive",
    es: "Muy larga",
  },
  none: {
    en: "None",
    es: "No tiene",
  },
  categoriesStatusLabel: {
    en: "Categories",
    es: "Categorías",
  },
  improvements: {
    en: "Category improvements",
    es: "Análisis de categorías",
  },
  improvementsSubtitle: {
    en: "Based on the queries your customers perform to find your location, we recommend changing the categories of your Google Business Profile as follows:",
    es: "Según las consultas que realizan sus clientes para encontrar su ubicación, le recomendamos cambiar las categorías de su perfil comercial de Google de la siguiente manera",
  },
  currentCategories: {
    en: "Current",
    es: "Actuales",
  },
  recomendation: {
    en: "Recommendation",
    es: "Recomendadas",
  },
  seeTutorial: {
    en: "See tutorial",
    es: "Ver tutorial",
  },
  categoriesMessage: {
    "text-danger": {
      es: "Necesitas agregar palabras clave!",
      en: "You need to add categories!",
    },
    "text-warning": {
      es: "Tus categorias pueden ser optimizadas",
      en: "Categories can be optimized",
    },
    "text-success": {
      es: "Tus palabras clave son correctas",
      en: "Your Categories are correct",
    },
  },
  seeImprovements: {
    es: "Sugeridas",
    en: "Suggested",
  },
  assignedKeywords: {
    en: "Categories",
    es: "Categorías",
  },
  seePlace: {
    es: "Ver página",
    en: "See place",
  },
  addAddress: {
    es: "Agrega una dirección",
    en: "Add an address",
  },
  primary: {
    es: "Primaria",
    en: "Primary",
  },
  secondary: {
    es: "Secundarias",
    en: "Secondary",
  },
  improvementsfooter: {
    en: "To improve",
    es: "Para mejorar",
  },
  improvementsfooterSort: {
    en: "# . update the order",
    es: "# . actualiza el orden",
  },
  improvementsfooterAdd: {
    en: "Add new categories",
    es: "Agregar nuevas categorias",
  },
  analyze: {
    en: "Last update: ",
    es: "Última actualización: ",
  },
  directionStatus: {
    INCOMPLETE: {
      en: "Incomplete address",
      es: "Dirección Incompleta",
    },
    MATCHED: {
      en: "Correct address",
      es: "Dirección correcta",
    },
    MISMATCHED: {
      en: "Mismatched direction",
      es: "Dirección no coincidente",
    },
  },
  summary: {
    captionTitle: {
      en: "Improve the following indicators to increase your score!",
      es: "Mejora los siguientes indicadores para subir tu score!",
    },
    placesWithoutKeywords: {
      en: "Locations with incorrect categories",
      es: "Locales con categorías incorrectas",
    },
    placesWithoutPosts: {
      en: "Locations without posts",
      es: "Locales sin Google Posts",
    },
    last15: {
      en: "(last 15 days)",
      es: "(últimos 15 días)",
    },
    placesWithoutPhotos: {
      en: "Locations without photos",
      es: "Locales sin fotos",
    },
    placesWithoutPhone: {
      en: "Locations without phone",
      es: "Locales sin teléfono",
    },
    placesWithoutWebsite: {
      en: "Locations without website",
      es: "Locales sin sitio web",
    },
    currentScore: {
      en: "Your current score is:",
      es: "Tu score actual es:",
    },
    seeVideo: {
      en: "Play video",
      es: "Ver video",
    },
    videoTitle: {
      en: "How to improve your Google My Business profile?",
      es: "¿Cómo mejorar tu perfil de Google My Business?",
    },
    videoSubtitle: {
      en: "Discover how to improve your Google My Business profile",
      es: "Descubre cómo mejorar tu perfil de Google My Business",
    },
    videoDescription: {
      en: "In this video we show you how to improve your Google My Business profile",
      es: "En este video te mostramos cómo mejorar tu perfil de Google My Business",
    },
    makePost: {
      en: "+ Post",
      es: "+ Postear",
    },
  },
  postHistoryCount: {
    en: "Posts",
    es: "Posts",
  },
  phone: {
    en: "Phone",
    es: "Teléfono",
  },
  notVerified: { es: "Sin verificar", en: "Unverified" },
  verified: { es: "Verificada", en: "Verified" },
  withoutKeywords: {
    es: "Problemas con Categorías",
    en: "Problems with Categories",
  },
  withoutPosts: {
    es: "Problemas con Google Posts",
    en: "Problems with Google Posts",
  },
  withoutPhotos: { es: "Problemas con Fotos", en: "Problems with Photos" },
  withoutPhone: { es: "Problemas con Teléfono", en: "Problems with Phone" },
  withoutWebsite: { es: "Problemas con Website ", en: "Problems with Website" },
  all: { es: "Ver Todos ", en: "All" },
  add: { es: "Agregar", en: "Add" },
  createPost: { es: "Crear post", en: "Create post" },
  noPosts: { es: "¡No tienes posts!", en: "No posts!" },
  score: {
    howItWorks: {
      es: "¿Cómo funciona el Score?",
      en: "How does the Score work?"
    },
  },
};


// const VideoModal = ({ isOpen, toggle, title, subtitle, description, url }) => {
//   return (
//     <Modal isOpen={isOpen} toggle={toggle} size="lg">
//       <ModalHeader toggle={toggle}>{title}</ModalHeader>
//       <ModalBody>
//         <div className="text-center">
//           <iframe
//             width="560"
//             height="315"
//             src="https://www.youtube.com/embed/Qx8ElTKfE-g?si=nfr9e32wx-bb4LDY&amp;start=1"
//             title="YouTube video player"
//             frameborder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             referrerpolicy="strict-origin-when-cross-origin"
//             allowfullscreen
//           ></iframe>
//         </div>
//         <h4>{subtitle}</h4>
//         <p>{description}</p>
//       </ModalBody>
//     </Modal>
//   );
// };

// const PostModal = ({ isOpen, toggle, socialLocationId }) => {
//   return (
//     <SocialLocationPostContainer
//       modal={{
//         isOpen,
//         toggle,
//       }}
//       socialLocationId={socialLocationId}
//     />
//   );
// };

// const CellFormatter = ({ content, actionButton, tooltip }) => {
//   const tpid = useMemo(() => Math.random().toString(), []);
//   return (
//     <div className="mt-4 d-inline-block  text-truncate">
//       <ReactTooltip id={tpid} effect="solid">
//         <span>{tooltip ? tooltip : content}</span>
//       </ReactTooltip>
//       <span data-tip data-for={tpid}>
//         {content}
//       </span>
//       {actionButton.show && (
//         <div>
//           <div
//             className={
//               actionButton.button
//                 ? "metric-button text-white "
//                 : "metric-call-to-action"
//             }
//             onClick={actionButton.onclickHandler}
//             style={{ color: actionButton.color || "#222" }}
//           >
//             <>
//               <MDBIcon
//                 fixed
//                 iconType={actionButton.iconType || "solid"}
//                 size="1x"
//                 // className={actionButton.className || " text-dark"}
//                 {...actionButton.icon}
//               />
//               <div>{actionButton.buttonText}</div>
//             </>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

export const BusinessProfilesTableContainer = withEventTracking(
  translate(BusinessProfilesTable)
);
