import React from "react";
import { Modal, ModalHeader, ModalBody } from "botbit-ui-components";
import "./ScoreScreen.scss";
import CardList from "../details/CardList/CardList";
import { translations } from "../../../../config/translations/GoogleBusinessProfile/Score";
import translate from "../../../../config/translations/translate";

const ScoreScreen = ({ isOpen, toggle, t}) => {
  const cardsData = [
    {
      title: t('analysis.title'),
      description: t('analysis.description'),
      icon: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.848 5.03924C19.8914 5.01512 19.9457 5 20.0058 5C20.066 5 20.1202 5.01512 20.1636 5.03924C20.2011 5.06007 20.2507 5.09815 20.3004 5.18281L37.4428 34.3495C37.532 34.5012 37.5302 34.66 37.4482 34.8078C37.407 34.882 37.3556 34.9309 37.3114 34.9583C37.2746 34.9811 37.2271 35 37.1482 35H2.86343C2.78454 35 2.73706 34.9811 2.70025 34.9583C2.65603 34.9309 2.60459 34.882 2.56347 34.8078C2.48146 34.66 2.47967 34.5012 2.56882 34.3495L19.7112 5.18281C19.761 5.09815 19.8105 5.06007 19.848 5.03924ZM22.4557 3.91605C21.346 2.02798 18.6656 2.02798 17.5559 3.91605L0.413518 33.0827C-0.729064 35.0268 0.64265 37.5 2.86343 37.5H37.1482C39.369 37.5 40.7407 35.0268 39.5981 33.0827L22.4557 3.91605Z"
            fill="#4285F4"
          />
          <path
            d="M17.5058 30C17.5058 28.6193 18.6251 27.5 20.0058 27.5C21.3865 27.5 22.5058 28.6193 22.5058 30C22.5058 31.3807 21.3865 32.5 20.0058 32.5C18.6251 32.5 17.5058 31.3807 17.5058 30Z"
            fill="#4285F4"
          />
          <path
            d="M17.7507 14.9876C17.6175 13.6557 18.6634 12.5 20.002 12.5C21.3405 12.5 22.3864 13.6557 22.2532 14.9876L21.3763 23.7562C21.3057 24.4623 20.7116 25 20.002 25C19.2923 25 18.6982 24.4623 18.6276 23.7562L17.7507 14.9876Z"
            fill="#4285F4"
          />
        </svg>
      ),
    },
    {
      title: t('scoring.title'),
      description: t('scoring.description'),
      icon: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.002 27.5H5.00195V35H10.002V27.5ZM22.502 17.5H17.502V35H22.502V17.5ZM35.002 5V35H30.002V5L35.002 5ZM30.002 2.5C28.6212 2.5 27.502 3.61929 27.502 5V35C27.502 36.3807 28.6212 37.5 30.002 37.5H35.002C36.3827 37.5 37.502 36.3807 37.502 35V5C37.502 3.61929 36.3827 2.5 35.002 2.5H30.002ZM15.002 17.5C15.002 16.1193 16.1212 15 17.502 15H22.502C23.8827 15 25.002 16.1193 25.002 17.5V35C25.002 36.3807 23.8827 37.5 22.502 37.5H17.502C16.1212 37.5 15.002 36.3807 15.002 35V17.5ZM2.50195 27.5C2.50195 26.1193 3.62124 25 5.00195 25H10.002C11.3827 25 12.502 26.1193 12.502 27.5V35C12.502 36.3807 11.3827 37.5 10.002 37.5H5.00195C3.62124 37.5 2.50195 36.3807 2.50195 35V27.5Z"
            fill="#4285F4"
          />
        </svg>
      ),
    },
    {
      title: t('classification.title'),
      description: t('classification.description'),
      icon: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.002 27.5H5.00195V35H10.002V27.5ZM22.502 17.5H17.502V35H22.502V17.5ZM35.002 5V35H30.002V5L35.002 5ZM30.002 2.5C28.6212 2.5 27.502 3.61929 27.502 5V35C27.502 36.3807 28.6212 37.5 30.002 37.5H35.002C36.3827 37.5 37.502 36.3807 37.502 35V5C37.502 3.61929 36.3827 2.5 35.002 2.5H30.002ZM15.002 17.5C15.002 16.1193 16.1212 15 17.502 15H22.502C23.8827 15 25.002 16.1193 25.002 17.5V35C25.002 36.3807 23.8827 37.5 22.502 37.5H17.502C16.1212 37.5 15.002 36.3807 15.002 35V17.5ZM2.50195 27.5C2.50195 26.1193 3.62124 25 5.00195 25H10.002C11.3827 25 12.502 26.1193 12.502 27.5V35C12.502 36.3807 11.3827 37.5 10.002 37.5H5.00195C3.62124 37.5 2.50195 36.3807 2.50195 35V27.5Z"
            fill="#4285F4"
          />
        </svg>
      ),
    },
  ];

  const tableData = [
    {
      value: "+30%",
      description: t('criteriaSection.criteria.primaryCategory'),
    },
    {
      value: "+25%",
      description: t('criteriaSection.criteria.secondaryCategories'),
    },
    {
      value: "+10%",
      description: t('criteriaSection.criteria.description'),
    },
    {
      value: "+10%",
      description: t('criteriaSection.criteria.googlePosts'),
    },
    {
      value: "+15%",
      description: t('criteriaSection.criteria.website'),
    },
    {
      value: "+10%",
      description: t('criteriaSection.criteria.photos'),
    },
  ];

  const scoreDataDescription = [
    {
      icon: (
        <svg
          width="36"
          height="13"
          viewBox="0 0 36 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="12.5"
            width="12"
            height="36"
            rx="2"
            transform="rotate(-90 0 12.5)"
            fill="#D80027"
          />
        </svg>
      ),
      description: t('scoreStatus.levels.low'),
    },
    {
      icon: (
        <svg
          width="36"
          height="13"
          viewBox="0 0 36 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="12.5"
            width="12"
            height="36"
            rx="2"
            transform="rotate(-90 0 12.5)"
            fill="#FF9407"
          />
        </svg>
      ),
      description: t('scoreStatus.levels.medium'),
    },
    {
      icon: (
        <svg
          width="36"
          height="13"
          viewBox="0 0 36 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="12.5"
            width="12"
            height="36"
            rx="2"
            transform="rotate(-90 0 12.5)"
            fill="#00974E"
          />
        </svg>
      ),
      description: t('scoreStatus.levels.high'),
    },
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div className="score-screen-container">
          <div className="cards-container">
            <CardList cardsData={cardsData} layout="rows" />
          </div>

          <div className="row col-12 mb-4">
            <div className="col-12 p-0 mock-component">
              <div className="background">
                <div className="score-screen-container">
                  <div className="score-container-current">
                    <div className="score-icon">
                      <svg
                        width="44"
                        height="44"
                        viewBox="0 0 44 44"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="44" height="44" rx="4" fill="#9291A5" />
                        <path
                          d="M25.4602 14.1464C25.3664 14.0527 25.2392 14 25.1066 14H18.8934C18.7608 14 18.6336 14.0527 18.5398 14.1464L14.1464 18.5398C14.0527 18.6336 14 18.7608 14 18.8934V25.1066C14 25.2392 14.0527 25.3664 14.1464 25.4602L18.5398 29.8536C18.6336 29.9473 18.7608 30 18.8934 30H25.1066C25.2392 30 25.3664 29.9473 25.4602 29.8536L29.8536 25.4602C29.9473 25.3664 30 25.2392 30 25.1066V18.8934C30 18.7608 29.9473 18.6336 29.8536 18.5398L25.4602 14.1464ZM22 18C22.5354 18 22.9538 18.4623 22.9005 18.995L22.5498 22.5025C22.5215 22.7849 22.2838 23 22 23C21.7162 23 21.4785 22.7849 21.4502 22.5025L21.0995 18.995C21.0462 18.4623 21.4646 18 22 18ZM22.0015 24C22.5538 24 23.0015 24.4477 23.0015 25C23.0015 25.5523 22.5538 26 22.0015 26C21.4493 26 21.0015 25.5523 21.0015 25C21.0015 24.4477 21.4493 24 22.0015 24Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="score-info">
                      <div className="score-text">
                        {t('scoreStatus.currentScore')}
                      </div>
                      <div className="progress-bar">
                        <svg
                          width="800"
                          height="7"
                          viewBox="0 0 907 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="8.89844"
                            y="0.257812"
                            width="500"
                            height="6.47976"
                            rx="3"
                          />
                          <rect
                            y="0.257812"
                            width="700"
                            height="6.47976"
                            rx="3"
                            fill="#9291A5"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="item-list">
                    {scoreDataDescription.map((item, index) => (
                      <div key={index} className="item-description">
                        <div className="item-icon">{item.icon}</div>
                        <h6 className="item-description">{item.description}</h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h3 className="font-bold-600">
              {t('criteriaSection.title')}
              </h3>
              <table className="w-100 p-0">
                <tbody className="row-tbody">
                  {tableData.map((row, index) => (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0
                          ? "even-row row-display"
                          : "odd-row row-display"
                      }
                    >
                      <td className="row-value no-border">{row.value}</td>
                      <td className="row-description no-border">
                        {row.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ScoreScreen.translations = {
  ...translations,
};

export default translate(ScoreScreen);