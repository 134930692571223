import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'botbit-ui-components';
import { UxFileUpload } from 'botbit-ui-components';
import { uploadFile } from '../../../../../api/companyApi';
import { updateGoogleBusinessProfile } from '../../../../../api/googleBusinessProfileApi';
import { useSelector } from 'react-redux';

let setModalOpen;

export const openPhotosModal = (locationId) => {
  if (setModalOpen) {
    setModalOpen(locationId);
  }
};

const PhotosModalContainer = ({t}) => {
  const [isPhotosModalOpen, setIsPhotosModalOpen] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const company = useSelector((state) => state.application.selectedCompany);
  
  setModalOpen = setIsPhotosModalOpen;

  const handleFileSelect = (file) => {
    setIsUploading(true);
    
    uploadFile(file, company.id)
      .then((res) => {
        const imageUrl = res.data.data;
        setUploadedImageUrl(imageUrl);
      })
      .catch((error) => {
        console.error('Error al subir archivo:', error);
        // alert('Error al subir la imagen');
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleApplyChanges = () => {
    if (!uploadedImageUrl) return;
    
    setIsSaving(true);
    updateGoogleBusinessProfile(isPhotosModalOpen, {
      photoUrl: uploadedImageUrl
    })
      .then((updateRes) => {
        // alert('¡Imagen guardada con éxito!');
        setIsPhotosModalOpen(undefined);
      })
      .catch((error) => {
        console.error('Error al guardar:', error);
        // alert('Error al guardar la imagen');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Modal 
      isOpen={isPhotosModalOpen !== undefined} 
      toggle={() => !isUploading && !isSaving && setIsPhotosModalOpen(undefined)}
      size="lg"
    >
      <ModalHeader toggle={() => !isUploading && !isSaving && setIsPhotosModalOpen(undefined)}>
        Subir fotos
      </ModalHeader>
      <ModalBody>
        <UxFileUpload
          id="photos-upload"
          acceptedFileTypes=".png,.jpg,.jpeg"
          maxFileSize={5}
          maxFileDimensions="1920x1080"
          uploadText="Haz clic para subir fotos"
          dragDropText="o arrastra y suelta tus imágenes aquí"
          onFileSelect={handleFileSelect}
          alertFunction={(message) => alert(message)}
        />
        
        {isUploading && (
          <div className="text-center mt-3">
            Subiendo imagen...
          </div>
        )}

        {uploadedImageUrl && !isUploading && (
          <div className="mt-4">
            <div className="text-center mb-3">
              <img 
                src={uploadedImageUrl} 
                alt="Preview" 
                style={{
                  maxWidth: '100%',
                  maxHeight: '300px',
                  objectFit: 'contain'
                }}
              />
            </div>
            
            <div className="text-center">
              <button 
                className="btn btn-primary"
                onClick={handleApplyChanges}
                disabled={isSaving}
              >
                {isSaving ? 'Guardando cambios...' : 'Aplicar cambios'}
              </button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PhotosModalContainer; 