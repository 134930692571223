import React from "react";

const iconSuccess = () => (

    <svg
        width="16"
        height="16"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.90424 2.73012C5.95153 2.57053 6.09908 2.46094 6.26667 2.46094H9.28889C9.41032 2.46094 9.52435 2.51888 9.59536 2.61667C9.66636 2.71446 9.68568 2.84017 9.64728 2.95452L8.30192 6.96093H11.1778C11.3221 6.96093 11.4537 7.04251 11.5171 7.17117C11.5805 7.29983 11.5646 7.4531 11.476 7.56615L6.18709 14.3161C6.07512 14.459 5.8763 14.5021 5.71452 14.4186C5.55273 14.335 5.47419 14.1487 5.52782 13.9756L6.88851 9.58592H4.37778C4.25872 9.58592 4.14663 9.53021 4.0753 9.43558C4.00398 9.34096 3.98176 9.21849 4.01535 9.10511L5.90424 2.73012Z"
            fill="#13589D"
        />
    </svg>
)

const iconError = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14 7.46094C14 11.3269 10.866 14.4609 7 14.4609C3.13401 14.4609 0 11.3269 0 7.46094C0 3.59494 3.13401 0.460938 7 0.460938C10.866 0.460938 14 3.59494 14 7.46094ZM7 3.96094C6.53152 3.96094 6.16545 4.36544 6.21207 4.8316L6.51897 7.90061C6.54368 8.14774 6.75164 8.33594 7 8.33594C7.24836 8.33594 7.45632 8.14774 7.48103 7.90061L7.78793 4.83159C7.83455 4.36544 7.46848 3.96094 7 3.96094ZM7.00135 9.21094C6.5181 9.21094 6.12635 9.60269 6.12635 10.0859C6.12635 10.5692 6.5181 10.9609 7.00135 10.9609C7.4846 10.9609 7.87635 10.5692 7.87635 10.0859C7.87635 9.60269 7.4846 9.21094 7.00135 9.21094Z"
            fill="#D80027"
        />
    </svg>
);

const iconModeration = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.25 1.39844C5.25 1.15681 5.44588 0.960938 5.6875 0.960938H8.3125C8.55412 0.960938 8.75 1.15681 8.75 1.39844C8.75 1.64006 8.55412 1.83594 8.3125 1.83594H7.875V2.77301C10.8434 3.19778 13.125 5.75023 13.125 8.83594C13.125 10.8113 12.1897 12.5679 10.7393 13.6874L11.266 14.2141C11.4368 14.3849 11.4368 14.6619 11.266 14.8328C11.0951 15.0037 10.8181 15.0037 10.6472 14.8328L9.99458 14.1801C9.10891 14.6774 8.08703 14.9609 7 14.9609C5.91297 14.9609 4.89109 14.6774 4.00542 14.1801L3.35277 14.8328C3.18191 15.0037 2.9049 15.0037 2.73405 14.8328C2.5632 14.6619 2.5632 14.3849 2.73405 14.2141L3.26073 13.6874C1.81034 12.5679 0.875 10.8113 0.875 8.83594C0.875 5.75023 3.15655 3.19778 6.125 2.77301V1.83594H5.6875C5.44588 1.83594 5.25 1.64006 5.25 1.39844ZM7.4375 5.77344C7.4375 5.53181 7.24162 5.33594 7 5.33594C6.75838 5.33594 6.5625 5.53181 6.5625 5.77344V8.71476L5.31235 10.7983C5.18803 11.0055 5.25522 11.2743 5.46241 11.3986C5.6696 11.5229 5.93834 11.4557 6.06265 11.2485L7.37515 9.06103C7.41595 8.99304 7.4375 8.91523 7.4375 8.83594V5.77344Z"
            fill="#13589D"
        />
        <path
            d="M0.752765 5.67475C0.291564 5.2737 0 4.68262 0 4.02344C0 2.81531 0.979377 1.83594 2.1875 1.83594C2.84668 1.83594 3.43776 2.1275 3.83881 2.5887C2.51066 3.2621 1.42616 4.34659 0.752765 5.67475Z"
            fill="#13589D"
        />
        <path
            d="M10.1612 2.5887C10.5622 2.1275 11.1533 1.83594 11.8125 1.83594C13.0206 1.83594 14 2.81531 14 4.02344C14 4.68262 13.7084 5.2737 13.2472 5.67475C12.5738 4.34659 11.4893 3.2621 10.1612 2.5887Z"
            fill="#13589D"
        />
    </svg>
);

const iconFinish = () => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.875 9C0.875 4.51269 4.51269 0.875 9 0.875C13.4873 0.875 17.125 4.51269 17.125 9C17.125 13.4873 13.4873 17.125 9 17.125C4.51269 17.125 0.875 13.4873 0.875 9ZM12.0086 7.48827C12.2092 7.20739 12.1442 6.81705 11.8633 6.61642C11.5824 6.41579 11.192 6.48084 10.9914 6.76173L8.29525 10.5364L6.94194 9.18306C6.69786 8.93898 6.30214 8.93898 6.05806 9.18306C5.81398 9.42714 5.81398 9.82286 6.05806 10.0669L7.93306 11.9419C8.06297 12.0719 8.24346 12.138 8.42655 12.1229C8.60964 12.1077 8.7768 12.0128 8.88358 11.8633L12.0086 7.48827Z"
            fill="#9291A5"
        />
    </svg>
);

const iconHeader = () => (
    <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.75 0.375C1.26675 0.375 0.875 0.766751 0.875 1.25V5.26256C0.875 5.49463 0.967187 5.71719 1.13128 5.88128L7.25628 12.0063C7.59799 12.348 8.15201 12.348 8.49372 12.0063L12.5063 7.99372C12.848 7.65201 12.848 7.09799 12.5063 6.75628L6.38128 0.631282C6.21719 0.467187 5.99463 0.375 5.76256 0.375H1.75ZM5.25 3.4375C5.25 4.16237 4.66237 4.75 3.9375 4.75C3.21263 4.75 2.625 4.16237 2.625 3.4375C2.625 2.71263 3.21263 2.125 3.9375 2.125C4.66237 2.125 5.25 2.71263 5.25 3.4375Z"
            fill="#13589D"
        />
    </svg>
);

const SvgModalFinish = () => {
    return (
      <svg
        width="135"
        height="133"
        viewBox="0 0 135 133"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M67.4982 116.049C93.8197 116.049 115.158 94.7113 115.158 68.3898C115.158 42.0683 93.8197 20.7305 67.4982 20.7305C41.1767 20.7305 19.8389 42.0683 19.8389 68.3898C19.8389 94.7113 41.1767 116.049 67.4982 116.049Z"
          fill="url(#paint0_radial_6366_127814)"
        />
        <path
          d="M61.6892 2.16372L48.3008 13.8216H67.5014V4.62331e-05C65.4293 -0.00665244 63.3549 0.714571 61.6892 2.16372Z"
          fill="#FDF0BC"
        />
        <path
          d="M73.2422 2.15027C71.5943 0.723456 69.5512 0.00669867 67.5059 0V13.8216H86.7065L73.2422 2.15027Z"
          fill="url(#paint1_linear_6366_127814)"
        />
        <path
          d="M20.7992 21.2787L18.0371 38.8158L32.7452 26.4746L23.8605 15.8862C22.2684 17.2126 21.1431 19.0994 20.7992 21.2787Z"
          fill="#FDF0BC"
        />
        <path
          d="M29.6417 13.8548C27.4624 13.8191 25.435 14.585 23.8652 15.8957L32.7499 26.4841L47.4579 14.1429L29.6417 13.8548Z"
          fill="url(#paint2_linear_6366_127814)"
        />
        <path
          d="M1.76136 62.2124L10.9162 77.4229L14.2499 58.5148L0.640449 56.1122C0.272023 58.153 0.622586 60.3212 1.76136 62.2124Z"
          fill="#FDF0BC"
        />
        <path
          d="M3.7523 50.8293C2.062 52.2048 1.00138 54.0938 0.639648 56.1057L14.2513 58.506L17.585 39.5979L3.7523 50.8293Z"
          fill="url(#paint3_linear_6366_127814)"
        />
        <path
          d="M13.4835 105.788L30.2748 111.553L20.6756 94.9249L8.70508 101.836C9.73444 103.635 11.3957 105.071 13.4835 105.788Z"
          fill="url(#paint4_linear_6366_127814)"
        />
        <path
          d="M7.7005 95.8006C7.28742 97.9397 7.68934 100.07 8.7053 101.845L20.6758 94.9342L11.0766 78.3059L7.7005 95.8006Z"
          fill="url(#paint5_linear_6366_127814)"
        />
        <path
          d="M50.482 131.648L67.05 125.273L49.0083 118.706L44.2812 131.695C46.2261 132.41 48.4233 132.441 50.482 131.648Z"
          fill="url(#paint6_linear_6366_127814)"
        />
        <path
          d="M39.625 127.701C40.6834 129.605 42.3603 130.979 44.2805 131.684L49.0076 118.695L30.9658 112.129L39.625 127.701Z"
          fill="url(#paint7_linear_6366_127814)"
        />
        <path
          d="M95.4483 127.68L104.043 112.146L86.001 118.713L90.728 131.702C92.6751 130.998 94.3788 129.612 95.4483 127.68Z"
          fill="url(#paint8_linear_6366_127814)"
        />
        <path
          d="M84.5997 131.637C86.6361 132.416 88.802 132.389 90.7268 131.695L85.9998 118.706L67.958 125.273L84.5997 131.637Z"
          fill="url(#paint9_linear_6366_127814)"
        />
        <path
          d="M127.334 95.7291L123.933 78.3059L114.334 94.9342L126.305 101.845C127.345 100.052 127.758 97.895 127.334 95.7291Z"
          fill="url(#paint10_linear_6366_127814)"
        />
        <path
          d="M121.574 105.73C123.632 105.018 125.276 103.604 126.305 101.836L114.335 94.9249L104.735 111.553L121.574 105.73Z"
          fill="url(#paint11_linear_6366_127814)"
        />
        <path
          d="M131.228 50.7579L117.422 39.5979L120.756 58.506L134.367 56.1057C134.017 54.0648 132.945 52.1467 131.228 50.7579Z"
          fill="url(#paint12_linear_6366_127814)"
        />
        <path
          d="M133.248 62.1365C134.369 60.2676 134.719 58.1285 134.369 56.1122L120.757 58.5125L124.091 77.4207L133.248 62.1365Z"
          fill="url(#paint13_linear_6366_127814)"
        />
        <path
          d="M105.305 13.8027L87.5557 14.1265L102.264 26.4677L111.148 15.8793C109.565 14.5441 107.513 13.7626 105.305 13.8027Z"
          fill="url(#paint14_linear_6366_127814)"
        />
        <path
          d="M114.161 21.2206C113.818 19.0681 112.712 17.2059 111.149 15.8862L102.265 26.4746L116.973 38.8158L114.161 21.2206Z"
          fill="url(#paint15_linear_6366_127814)"
        />
        <path
          d="M67.5054 16.8197C39.295 16.8197 16.4258 39.6867 16.4258 67.897C16.4258 96.1074 39.295 118.977 67.5054 118.977C95.7157 118.977 118.585 96.1074 118.585 67.897C118.585 39.6867 95.7157 16.8197 67.5054 16.8197ZM67.5054 114.783C41.6105 114.783 20.6191 93.7919 20.6191 67.897C20.6191 42.0022 41.6105 21.0108 67.5054 21.0108C93.4002 21.0108 114.392 42.0022 114.392 67.897C114.392 93.7919 93.4002 114.783 67.5054 114.783Z"
          fill="url(#paint16_linear_6366_127814)"
        />
        <path
          d="M67.5043 10.303C35.6946 10.303 9.90918 36.0884 9.90918 67.8981C9.90918 99.7079 35.6946 125.493 67.5043 125.493C99.3141 125.493 125.099 99.7079 125.099 67.8981C125.099 36.0884 99.3141 10.303 67.5043 10.303ZM67.5043 118.953C39.3074 118.953 16.4493 96.0951 16.4493 67.8981C16.4493 39.7012 39.3074 16.8453 67.5043 16.8453C95.7013 16.8453 118.559 39.7034 118.559 67.9004C118.559 96.0973 95.7013 118.953 67.5043 118.953Z"
          fill="url(#paint17_linear_6366_127814)"
        />
        <path
          d="M58.0556 42.7489C58.2843 41.9773 58.9977 41.4474 59.808 41.4474H74.4204C75.0076 41.4474 75.5589 41.7276 75.9022 42.2004C76.2455 42.6732 76.3389 43.281 76.1532 43.8339L69.6484 63.2049H83.5532C84.2508 63.2049 84.8875 63.5993 85.194 64.2214C85.5005 64.8435 85.4233 65.5845 84.995 66.1311L59.4232 98.7673C58.8819 99.4582 57.9206 99.6666 57.1383 99.2626C56.3561 98.8586 55.9763 97.9576 56.2356 97.121L62.8146 75.8967H50.6752C50.0996 75.8967 49.5576 75.6273 49.2127 75.1698C48.8679 74.7123 48.7604 74.1202 48.9229 73.572L58.0556 42.7489Z"
          fill="white"
        />
        <defs>
          <radialGradient
            id="paint0_radial_6366_127814"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(67.4985 68.3888) scale(47.6593)"
          >
            <stop offset="0.8428" stopColor="#2383E2" />
            <stop offset="1" stopColor="#1168BF" />
          </radialGradient>
          <linearGradient
            id="paint1_linear_6366_127814"
            x1="68.4446"
            y1="6.91079"
            x2="86.0763"
            y2="6.91079"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE998" />
            <stop offset="1" stopColor="#FCC15B" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_6366_127814"
            x1="29.2339"
            y1="20.4078"
            x2="46.8305"
            y2="5.64134"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE998" />
            <stop offset="1" stopColor="#FCC15B" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_6366_127814"
            x1="17.9"
            y1="39.5584"
            x2="7.97018"
            y2="55.8716"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCC15B" />
            <stop offset="1" stopColor="#FEE998" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_6366_127814"
            x1="13.2322"
            y1="97.9934"
            x2="30.4912"
            y2="109.342"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC05F" />
            <stop offset="1" stopColor="#ED892B" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_6366_127814"
            x1="11.2735"
            y1="78.6678"
            x2="14.347"
            y2="98.0546"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCC15B" />
            <stop offset="1" stopColor="#FEE998" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_6366_127814"
            x1="47.5088"
            y1="125.432"
            x2="64.5313"
            y2="129.215"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEAC3E" />
            <stop offset="1" stopColor="#F08223" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_6366_127814"
            x1="30.1145"
            y1="111.98"
            x2="45.7184"
            y2="124.747"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFA135" />
            <stop offset="1" stopColor="#FEB431" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_6366_127814"
            x1="104.11"
            y1="112.035"
            x2="88.5459"
            y2="125.432"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAA3D" />
            <stop offset="1" stopColor="#ED8B1C" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_6366_127814"
            x1="68.0264"
            y1="126.517"
            x2="87.8859"
            y2="125.413"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF962D" />
            <stop offset="1" stopColor="#E56005" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_6366_127814"
            x1="124.663"
            y1="78.3758"
            x2="120.67"
            y2="97.815"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDBD41" />
            <stop offset="1" stopColor="#FEB334" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_6366_127814"
            x1="129.898"
            y1="81.791"
            x2="110.697"
            y2="115.046"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F6942F" />
            <stop offset="1" stopColor="#EA6F11" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_6366_127814"
            x1="116.458"
            y1="40.157"
            x2="128.017"
            y2="57.2846"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDBD41" />
            <stop offset="1" stopColor="#FEB334" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_6366_127814"
            x1="123.371"
            y1="77.3723"
            x2="127.889"
            y2="57.4076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FD9D24" />
            <stop offset="1" stopColor="#FF9515" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_6366_127814"
            x1="106.483"
            y1="20.9132"
            x2="88.6202"
            y2="12.2969"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCC15B" />
            <stop offset="1" stopColor="#FEE998" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_6366_127814"
            x1="106.57"
            y1="19.8215"
            x2="115.081"
            y2="38.7354"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9515" />
            <stop offset="1" stopColor="#FFBE41" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_6366_127814"
            x1="67.5057"
            y1="16.8188"
            x2="67.5057"
            y2="118.978"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0226" stopColor="#FD9A18" />
            <stop offset="0.3764" stopColor="#FEC752" />
            <stop offset="1" stopColor="#FFA422" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_6366_127814"
            x1="67.5047"
            y1="10.1342"
            x2="67.5047"
            y2="125.325"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDB69" />
            <stop offset="0.1799" stopColor="#FEE7B0" />
            <stop offset="0.24" stopColor="#FEE9BA" />
            <stop offset="0.2592" stopColor="#FDECD0" />
            <stop offset="0.2953" stopColor="#FEDA92" />
            <stop offset="0.3254" stopColor="#FFCD64" />
            <stop offset="0.3405" stopColor="#FFC853" />
            <stop offset="0.7613" stopColor="#FF9114" />
            <stop offset="1" stopColor="#F57E16" />
          </linearGradient>
        </defs>
      </svg>
    );
  };

export default { iconSuccess, iconError, iconModeration, iconFinish, iconHeader, SvgModalFinish };