export const translations = {
  categories: {
    label: {
      es: "Categorías",
      en: "Categories",
    },
    subtitle: {
      es: "Actuales",
      en: "Current",
    },
    subtitle2: {
      es: "Primarias",
      en: "Primary",
    },
    subtitle3: {
      es: "Secundarias",
      en: "Secondary",
    },
    status: {
      pending: {
        es: "¡Posibilidad de mejora en tus categorías!",
        en: "Opportunity to improve in your categories!",
      },
      success: {
        es: "¡Excelente! Ya tienes +20% en tu score por las categorias. ",
        en: "Great! You have +20% in your score for the categories.",
      },
      moderation: {
        es: "¡Tus cambios se han aplicado y están siendo verificados!",
        en: "Your changes have been applied and are being verified!",
      },
      error: {
        es: "No tener las categorias esta afectando el posicionamiento en Google de esta sucursal.",
        en: "Not having the categories is affecting the positioning of this branch on Google.",
      },
    },
    button: {
      es: "Ver recomendadas",
      en: "See recommended",
    },
    modal: {
      title: {
        es: "Análisis de categorías",
        en: "Categories analysis",
      },
      subtitle: {
        es: "Analizamos tus palabras claves y en base al resultado, te recomendamos reordenarlas o agregar en el caso de que falten.",
        en: "We analyze your keywords and based on the result, we recommend you to reorder them or add them in case they are missing.",
      },
      title2: {
        es: "Actuales",
        en: "Current",
      },
      title3: {
        es: "Recomendadas",
        en: "Recommended",
      },
      title4: {
        es: "Primaria",
        en: "Primary",
      },
      title5: {
        es: "Secundarias",
        en: "Secondary",
      },
      title6: {
        es: "Para mejorar:",
        en: "To improve:",
      },
      checkbox: {
        es: "sugeridas",
        en: "suggested",
      },
      button: {
        es: "Aplicar cambios",
        en: "Apply changes",
      },
      verified: {
        es: "Verificado",
        en: "Verified"
      },
      notVerified: {
        es: "No Verificado",
        en: "Not Verified"
      },
      loading: {
        es: "Cargando sugerencias...",
        en: "Loading suggestions..."
      }
    },
  },
  tutorial: {
    button: {
      es: "Ver Tutorial",
      en: "Watch Tutorial"
    }
  },
  modalFinish: {
    title: {
      es: "¡Genial! Sumaste puntos al Score por mejorar este local!",
      en: "Great! You added points to the Score for improving this location!"
    },
    subtitle: {
      es: "Importante: Google esta verificando los cambios antes de aplicarlos al perfil de esta sucursal.",
      en: "Important: Google is verifying the changes before applying them to this branch's profile."
    }
  },
  noData: {
    es: "No hay datos disponibles",
    en: "No data available"
  },
  saveAndReturn: {
    es: "Terminar y volver",
    en: "Save and return"
  },
  titleTutorial: {
    es: "Tutorial de soporte sugerido por RAY:",
    en: "Tutorial of support suggested by RAY:"
  },
  mainCategory: {
    es: "Primaria",
    en: "Primary"
  },
  secondaryCategories: {
    es: "Secundarias",
    en: "Secondaries"
  },
  currentCategories: {
    es: "Actuales",
    en: "Current"
  },
  buttons: {
    cancel: {
      es: "Cancelar",
      en: "Cancel"
    },
    apply: {
      es: "Aplicar",
      en: "Apply"
    }
  },
  actionableConfigs: {
    categories: {
      title: {
        es: "Categorías",
        en: "Categories"
      },
      states: {
        success: {
          message: {
            es: "Las categorías están configuradas correctamente",
            en: "Categories are properly configured"
          },
          buttonText: {
            es: "Ver Recomendadas",
            en: "See Recommended"
          }
        },
        error: {
          message: {
            es: "Las categorías necesitan ser configuradas",
            en: "Categories need to be configured"
          },
          buttonText: {
            es: "Ver Recomendadas",
            en: "See Recommended"
          }
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google."
          },
          buttonText: {
            es: "Ver Recomendadas",
            en: "See Recommended"
          }
        }
      }
    },
    photos: {
      title: {
        es: "Fotos de la marca",
        en: "Brand Photos"
      },
      states: {
        success: {
          message: {
            es: "¡Excelente! Tienes suficientes fotos publicadas.",
            en: "Excellent! You have enough photos published."
          },
          placeholder: {
            es: "Sigue publicando fotos para este local directamente desde RAY.",
            en: "Keep publishing photos for this location directly from RAY."
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos"
          }
        },
        error: {
          message: {
            es: "Tienes menos de 3 fotos publicadas.",
            en: "You have less than 3 photos published."
          },
          placeholder: {
            es: "Recuerda que no se contabilizarán las publicadas por tus clientes.",
            en: "Remember that photos published by your customers will not be counted."
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos"
          }
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google."
          },
          buttonText: {
            es: "+ Subir fotos",
            en: "+ Upload photos"
          }
        }
      }
    },
    googlePost: {
      title: {
        es: "Google Post",
        en: "Google Post"
      },
      states: {
        success: {
          message: {
            es: "¡Excelente! Has publicado en los últimos 15 días.",
            en: "Excellent! You have posted in the last 15 days."
          },
          placeholder: {
            es: "Sigue creando contenido relevante.",
            en: "Keep creating relevant content."
          },
          buttonText: {
            es: "+ Crear post",
            en: "+ Create post"
          }
        },
        error: {
          message: {
            es: "No has publicado en los últimos 15 días.",
            en: "You haven't posted in the last 15 days."
          },
          placeholder: {
            es: "Publica un nuevo post para atraer más tráfico.",
            en: "Publish a new post to attract more traffic."
          },
          buttonText: {
            es: "+ Crear post",
            en: "+ Create post"
          }
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google."
          },
          buttonText: {
            es: "+ Crear post",
            en: "+ Create post"
          }
        }
      }
    },
    phone: {
      title: {
        es: "Teléfono",
        en: "Phone"
      }
    },
    website: {
      title: {
        es: "Website",
        en: "Website"
      },
      states: {
        success: {
          message: {
            es: "Tu sitio web está configurado correctamente.",
            en: "Your website is properly configured."
          },
          placeholder: {
            es: "Mantén tu sitio actualizado.",
            en: "Keep your site updated."
          },
          buttonText: {
            es: "Editar",
            en: "Edit"
          }
        },
        error: {
          message: {
            es: "Falta la configuración del sitio web.",
            en: "Website configuration is missing."
          },
          placeholder: {
            es: "Agrega un enlace a tu sitio.",
            en: "Add a link to your site."
          },
          buttonText: {
            es: "Editar",
            en: "Edit"
          }
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google."
          },
          buttonText: {
            es: "Editar",
            en: "Edit"
          }
        }
      }
    },
    description: {
      title: {
        es: "Descripción",
        en: "Description"
      },
      states: {
        success: {
          message: {
            es: "La descripción es adecuada.",
            en: "The description is adequate."
          },
          placeholder: {
            es: "Edita la descripción si lo consideras necesario.",
            en: "Edit the description if you consider it necessary."
          },
          buttonText: {
            es: "Editar",
            en: "Edit"
          }
        },
        error: {
          message: {
            es: "Falta una descripción detallada.",
            en: "A detailed description is missing."
          },
          placeholder: {
            es: "Agrega una descripción atractiva para tu negocio.",
            en: "Add an attractive description for your business."
          },
          buttonText: {
            es: "Editar",
            en: "Edit"
          }
        },
        moderation: {
          message: {
            es: "¡Ya tienes +10% en tu score! Los cambios se han aplicado y están siendo verificados por google.",
            en: "You already have +10% in your score! Changes have been applied and are being verified by Google."
          },
          buttonText: {
            es: "Editar",
            en: "Edit"
          }
        }
      }
    },
    address: {
      title: {
        es: "Dirección",
        en: "Address"
      }
    }
  },
  score: {
    howItWorks: {
      es: "¿Cómo funciona el Score?",
      en: "How does the Score work?"
    },
  },
};
