import React, { useEffect, useState } from "react";
import translate from "../../../../config/translations/translate";
import {
  UxMetricOutstandingComponent,
  UxActionableEditorCard,
} from "botbit-ui-components";
import ModalCategories from "./Categories/ModalCategories";
import { translations } from "../../../../config/translations/GoogleBusinessProfile/GoogleBusinessProfile";
import {
  getGoogleBusinessProfile,
  updateGoogleBusinessProfile,
  postPlaceImprovementsSuggestions,
} from "../../../../api/googleBusinessProfileApi";
import "./styles.scss";
import CardList from "./CardList/CardList";
import ScoreScreen from "../ScoreDescription/ScoreScreen";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import PostModalContainer from "./PostModal/PostModalContainer";
import { openPostModal } from "./PostModal/PostModalContainer";
import PhotosModalContainer, {
  openPhotosModal,
} from "./PhotosModal/PhotosModalContainer";
import ModalFinish from "./ModalFinish/ModalFinish";
//Modal when redirect
import { useHistory } from "react-router-dom";
import Icons from "./Icons/Icons";

const cardsData = [
  {
    title: "Analizamos tus locales",
    description:
      "En RAY, realizamos un análisis exhaustivo de tus locales de Google para obtener información sobre el estado de los mismos.",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.11428 13.7329C4.16447 12.3439 3.60344 10.6292 3.60344 8.7738C3.60344 4.14248 7.09894 0.388062 11.4109 0.388062C15.7228 0.388062 19.2183 4.14248 19.2183 8.7738C19.2183 13.4051 15.7228 17.1595 11.4109 17.1595C9.68291 17.1595 8.08608 16.5566 6.79276 15.536L6.7938 15.5368C6.75838 15.5884 6.71892 15.6378 6.67544 15.6845L2.05049 20.652C1.58142 21.1558 0.820896 21.1558 0.351822 20.652C-0.117252 20.1482 -0.117252 19.3313 0.351822 18.8275L4.97677 13.86C5.02026 13.8133 5.06625 13.7709 5.11428 13.7329ZM4.80458 8.7738C4.80458 12.6926 7.76231 15.8694 11.4109 15.8694C15.0594 15.8694 18.0171 12.6926 18.0171 8.7738C18.0171 4.85499 15.0594 1.67817 11.4109 1.67817C7.76231 1.67817 4.80458 4.85499 4.80458 8.7738Z"
          fill="#4285F4"
        />
      </svg>
    ),
  },
  {
    title: "Puntuamos tus locales",
    description:
      "Una vez completado el análisis, RAY le asigna una “puntuación” a cada uno de tus locales en base a las opotunidades de mejora que fueron identificadas durante dicho análisis.",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.002 27.5H5.00195V35H10.002V27.5ZM22.502 17.5H17.502V35H22.502V17.5ZM35.002 5V35H30.002V5L35.002 5ZM30.002 2.5C28.6212 2.5 27.502 3.61929 27.502 5V35C27.502 36.3807 28.6212 37.5 30.002 37.5H35.002C36.3827 37.5 37.502 36.3807 37.502 35V5C37.502 3.61929 36.3827 2.5 35.002 2.5H30.002ZM15.002 17.5C15.002 16.1193 16.1212 15 17.502 15H22.502C23.8827 15 25.002 16.1193 25.002 17.5V35C25.002 36.3807 23.8827 37.5 22.502 37.5H17.502C16.1212 37.5 15.002 36.3807 15.002 35V17.5ZM2.50195 27.5C2.50195 26.1193 3.62124 25 5.00195 25H10.002C11.3827 25 12.502 26.1193 12.502 27.5V35C12.502 36.3807 11.3827 37.5 10.002 37.5H5.00195C3.62124 37.5 2.50195 36.3807 2.50195 35V27.5Z"
          fill="#4285F4"
        />
      </svg>
    ),
  },
  {
    title: "Clasificamos tus locales",
    description:
      "Después de puntuar tus locales, RAY los clasifica visualmente mediante un sistema de colores para una rápida identificación de su estado.",
    icon: (
      <svg
        width="40"
        height="36"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8461 3.15252C19.8895 3.1284 19.9437 3.11328 20.0039 3.11328C20.064 3.11328 20.1183 3.1284 20.1617 3.15252C20.1992 3.17336 20.2487 3.21143 20.2985 3.29609L37.4409 32.4628C37.53 32.6144 37.5282 32.7732 37.4462 32.9211C37.4051 32.9953 37.3536 33.0442 37.3094 33.0716C37.2726 33.0944 37.2251 33.1133 37.1462 33.1133H2.86148C2.78258 33.1133 2.73511 33.0944 2.6983 33.0716C2.65407 33.0442 2.60264 32.9953 2.56152 32.9211C2.47951 32.7732 2.47771 32.6144 2.56687 32.4628L19.7093 3.29609C19.759 3.21143 19.8086 3.17336 19.8461 3.15252ZM22.4538 2.02934C21.3441 0.141263 18.6636 0.141263 17.5539 2.02934L0.411564 31.196C-0.731017 33.14 0.640697 35.6133 2.86148 35.6133H37.1462C39.367 35.6133 40.7387 33.14 39.5962 31.196L22.4538 2.02934Z"
          fill="#4285F4"
        />
        <path
          d="M17.5039 28.1133C17.5039 26.7326 18.6231 25.6133 20.0039 25.6133C21.3846 25.6133 22.5039 26.7326 22.5039 28.1133C22.5039 29.494 21.3846 30.6133 20.0039 30.6133C18.6231 30.6133 17.5039 29.494 17.5039 28.1133Z"
          fill="#4285F4"
        />
        <path
          d="M17.7488 13.1009C17.6156 11.769 18.6615 10.6133 20 10.6133C21.3385 10.6133 22.3844 11.769 22.2512 13.1009L21.3744 21.8695C21.3038 22.5756 20.7096 23.1133 20 23.1133C19.2904 23.1133 18.6962 22.5756 18.6256 21.8695L17.7488 13.1009Z"
          fill="#4285F4"
        />
      </svg>
    ),
  },
];

const CategoryContent = ({ value, buttonText, onButtonClick, disabled, t }) => (
  <div className="card-categories">
    <div className="title-categories">
      <h4>{t("currentCategories")}</h4>
    </div>
    <div className="flex-row">
      <div>
        <h5>{t("mainCategory")}</h5>
        <p className="font-bold">{value.primaryCategory}</p>
      </div>
      <div>
        <h5>{t("secondaryCategories")}</h5>
        <p>
          {value.secondaryCategories && value.secondaryCategories.join(" | ")}
        </p>
      </div>
    </div>
    <button
      className="card-button-categories"
      onClick={onButtonClick}
      disabled={disabled}
      style={disabled ? { opacity: 0.4, cursor: "not-allowed" } : {}}
    >
      {buttonText}
    </button>
  </div>
);

const RenderActionableCards = ({
  summary,
  actionableConfigs,
  setSummary,
  setTotalPoints,
  socialLocationId,
  totalPoints,
  t,
}) => {
  const [editableFields, setEditableFields] = useState({
    website: false,
    description: false,
  });

  const [isCategoriesModalOpen, setCategoriesModalOpen] = useState(false);

  const [editedValues, setEditedValues] = useState({
    website: (summary && summary.website) || "",
    description: (summary && summary.description) || "",
  });

  const [originalValues, setOriginalValues] = useState({
    website: (summary && summary.website) || "",
    description: (summary && summary.description) || "",
  });

  const renderCardContent = ({
    inputType,
    placeholder,
    buttonText,
    value,
    readOnly,
    onChange,
    onButtonClick,
    key,
    onApply,
    onCancel,
    isEditing,
    disabled,
  }) => {
    const isSpecialButton =
      key === "photosValueIsOk" || key === "postHistoryCountValueIsOk";

    const contentMap = {
      text: (
        <div className="input-container">
          <input
            className="input-example-highlighted"
            type="text"
            placeholder={placeholder}
            value={value || ""}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            style={
              disabled
                ? {
                    opacity: 0.7,
                    cursor: "not-allowed",
                    backgroundColor: "#f5f5f5",
                  }
                : {}
            }
          />
          {isSpecialButton ? (
            <button
              className="edit-button"
              onClick={onButtonClick}
              disabled={disabled}
              style={
                disabled
                  ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                  : {}
              }
            >
              {buttonText}
            </button>
          ) : (
            <>
              {!isEditing ? (
                <button
                  className="edit-button"
                  onClick={onButtonClick}
                  disabled={disabled}
                  style={
                    disabled
                      ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                      : {}
                  }
                >
                  {buttonText}
                </button>
              ) : (
                <div className="action-buttons">
                  <button className="cancel-button" onClick={onCancel}>
                    {t("buttons.cancel")}
                  </button>
                  <button className="apply-button" onClick={onApply}>
                    {t("buttons.apply")}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ),
      paragraph: (
        <div className="input-container">
          <textarea
            className="textarea-example-no-highlighted"
            placeholder={placeholder}
            rows="3"
            value={value || ""}
            readOnly={readOnly}
            onChange={onChange}
            disabled={disabled}
            style={
              disabled
                ? {
                    opacity: 0.7,
                    cursor: "not-allowed",
                    backgroundColor: "#f5f5f5",
                  }
                : {}
            }
          />
          {isSpecialButton ? (
            <button
              className="no-highlighted-button-textarea"
              onClick={onButtonClick}
            >
              {buttonText}
            </button>
          ) : (
            <>
              {!isEditing ? (
                <button
                  className="no-highlighted-button-textarea"
                  onClick={onButtonClick}
                  disabled={disabled}
                  style={
                    disabled
                      ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                      : {}
                  }
                >
                  {buttonText}
                </button>
              ) : (
                <div className="no-highlighted-button-textarea">
                  <button
                    className="cancel-button"
                    onClick={onCancel}
                    disabled={disabled}
                    style={
                      disabled
                        ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                        : {}
                    }
                  >
                    {t("buttons.cancel")}
                  </button>
                  <button
                    className="apply-button"
                    onClick={onApply}
                    disabled={disabled}
                    style={
                      disabled
                        ? { opacity: 0.7, cursor: "not-allowed", color: "gray" }
                        : {}
                    }
                  >
                    {t("buttons.apply")}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ),
      display: (
        <p className="input-example-highlighted">{value || placeholder}</p>
      ),
      categories: (
        <>
          <ModalCategories
            isOpen={isCategoriesModalOpen}
            socialLocationId={socialLocationId}
            toggle={() => setCategoriesModalOpen(!isCategoriesModalOpen)}
            title={t("categories.modal.title")}
            subtitle={t("categories.modal.subtitle")}
            t={t}
            onApply={onApply}
            placeData={summary}
          />
          <CategoryContent
            value={value}
            buttonText={buttonText}
            onButtonClick={onButtonClick}
            disabled={disabled}
            t={t}
          />
        </>
      ),
    };
    return contentMap[inputType] || null;
  };

  const handleButtonClick = (key) => {
    if (key === "categories") {
      setCategoriesModalOpen(true);
      return;
    }

    if (key === "photosValueIsOk") {
      openPhotosModal(summary.id);
      return;
    }

    if (key === "postHistoryCountValueIsOk") {
      openPostModal(summary.id);
      return;
    }

    const fieldKey = key.replace("ValueIsOk", "");
    setEditableFields((prev) => ({
      ...prev,
      [fieldKey]: true,
    }));
  };

  const handleInputChange = (key, value) => {
    setEditedValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleApply = async (key) => {
    const fieldKey = key.replace("ValueIsOk", "");

    try {
      const updateData = {
        [fieldKey]: editedValues[fieldKey],
      };
      let response;
      if (fieldKey == "categories") {
        response = await postPlaceImprovementsSuggestions(socialLocationId);
      } else {
        response = await updateGoogleBusinessProfile(summary.id, updateData);
      }

      if (response.data.result === "success") {
        setEditableFields((prev) => ({
          ...prev,
          [fieldKey]: false,
        }));

        setOriginalValues((prev) => ({
          ...prev,
          [fieldKey]: editedValues[fieldKey],
        }));
        let newTotalPoints = 0;
        setSummary((prevSummary) => {
          newTotalPoints = response.data.data.score - prevSummary.score;
          return {
            ...response.data.data,
            [fieldKey]: editedValues[fieldKey],
            [`${fieldKey}ValueIsOk`]: true,
            [`${fieldKey}WasEdited`]: true,
          };
        });
        setTotalPoints((prev) => prev + newTotalPoints);
      } else {
        //TODO: Levantar un alert nuestro.
        //alert('Hubo un problema al guardar los cambios. Por favor, intenta nuevamente.');
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      //TODO: Levantar un alert nuestro
      // alert('Error al conectar con el servidor. Por favor, verifica tu conexión e intenta nuevamente.');

      setEditedValues((prev) => ({
        ...prev,
        [fieldKey]: originalValues[fieldKey],
      }));
    }
  };

  const handleCancel = (key) => {
    const fieldKey = key.replace("ValueIsOk", "");
    setEditedValues((prev) => ({
      ...prev,
      [fieldKey]: originalValues[fieldKey],
    }));
    setEditableFields((prev) => ({
      ...prev,
      [fieldKey]: false,
    }));
  };
  return actionableConfigs.map((config) => {
    let stateKey = "displayOnly";

    const isPhotosKey = config.key === "photosValueIsOk";
    const isPostHistoryKey = config.key === "postHistoryCountValueIsOk";

    if (config.key === "categories") {
      if (summary.categoriesImprovementWasApplied) {
        stateKey = "moderation";
      } else {
        const categoriesAreValid =
          summary.primaryCategoryMatched && summary.secondaryCategoryMatched;
        stateKey = categoriesAreValid ? "success" : "error";
      }
    } else if (isPhotosKey || isPostHistoryKey) {
      const isModeration =
        (isPhotosKey && summary.photosWasEdited) ||
        (isPostHistoryKey && summary.postHistoryWasEdited);

      stateKey = isModeration
        ? "moderation"
        : summary[config.key] === true
        ? "success"
        : "error";
    } else if (config.key !== "address" && config.key !== "phone") {
      const isModeration =
        (config.key === "websiteValueIsOk" && summary.websiteWasEdited) ||
        (config.key === "descriptionValueIsOk" && summary.descriptionWasEdited);
      stateKey = isModeration
        ? "moderation"
        : summary[config.key] === true
        ? "success"
        : "error";
    }

    const stateConfig = config.states[stateKey];
    if (!stateConfig) return null;

    const {
      status,
      message,
      placeholder,
      buttonText,
      inputType,
      valueKey,
      readOnly,
      disabled,
    } = stateConfig;

    const fieldKey = config.key.replace("ValueIsOk", "");
    const isFieldEditable = editableFields[fieldKey];

    let value;
    if (config.key === "categories") {
      value = {
        primaryCategory: summary.categories && summary.categories[0],
        secondaryCategories: summary.categories && summary.categories.slice(1),
      };
    } else if (isPhotosKey || isPostHistoryKey) {
      value = placeholder;
    } else if (config.key === "websiteValueIsOk") {
      value = editedValues.website;
    } else if (config.key === "descriptionValueIsOk") {
      value = editedValues.description;
    } else if (valueKey) {
      value = summary[valueKey];
    }

    return (
      <UxActionableEditorCard
        key={config.key}
        title={config.title}
        bonusAmount={config.bonusAmount}
        highlighted={config.highlighted}
        status={status}
        icon={{
          iconSuccess: Icons.iconSuccess(),
          iconError: Icons.iconError(),
          iconModeration: Icons.iconModeration(),
          iconHeader: Icons.iconHeader(),
          iconFinish: Icons.iconFinish(),
        }}
        label={{
          statusMessage: message || "",
        }}
        showMessage={true}
      >
        <div className="card-example-highlighted">
          {renderCardContent({
            key: config.key,
            inputType,
            placeholder,
            buttonText,
            value,
            readOnly: !isFieldEditable,
            onChange: (e) => handleInputChange(fieldKey, e.target.value),
            onButtonClick: () => handleButtonClick(config.key),
            onApply: () => handleApply(config.key),
            onCancel: () => handleCancel(config.key),
            isEditing: isFieldEditable,
            disabled: config.states[stateKey].disabled,
          })}
        </div>
      </UxActionableEditorCard>
    );
  });
};

const metricsOutstandingConfiguration = (summary, t, openModal) => ({
  outstanding: {
    icon: "",
    content: "React.Component",
    value: summary.score || 0,
    title: t(""),
    showBar: true,
  },
  scoreInfoLink: {
    text: t("score.howItWorks"),
    onclickHandler: openModal,
  },
});

const translateConfig = (config, t) => ({
  ...config,
  title: t(config.titleKey),
  states: Object.entries(config.states).reduce(
    (acc, [key, state]) => ({
      ...acc,
      [key]: {
        ...state,
        message: state.messageKey ? t(state.messageKey) : state.message,
        buttonText: state.buttonTextKey
          ? t(state.buttonTextKey)
          : state.buttonText,
        placeholder: state.placeholderKey
          ? t(state.placeholderKey)
          : state.placeholder,
      },
    }),
    {}
  ),
});

const actionableConfigs = [
  {
    key: "categories",
    titleKey: "actionableConfigs.categories.title",
    bonusAmount: 0,
    highlighted: true,
    states: {
      success: {
        status: "success",
        messageKey: "actionableConfigs.categories.states.success.message",
        inputType: "categories",
        buttonTextKey: "actionableConfigs.categories.states.success.buttonText",
      },
      error: {
        status: "error",
        messageKey: "actionableConfigs.categories.states.error.message",
        inputType: "categories",
        buttonTextKey: "actionableConfigs.categories.states.error.buttonText",
      },
      moderation: {
        status: "moderation",
        messageKey: "actionableConfigs.categories.states.moderation.message",
        inputType: "categories",
        buttonTextKey:
          "actionableConfigs.categories.states.moderation.buttonText",
        readOnly: true,
        disabled: true,
      },
    },
  },
  {
    key: "photosValueIsOk",
    titleKey: "actionableConfigs.photos.title",
    bonusAmount: 10,
    highlighted: true,
    states: {
      success: {
        status: "success",
        messageKey: "actionableConfigs.photos.states.success.message",
        placeholderKey: "actionableConfigs.photos.states.success.placeholder",
        buttonTextKey: "actionableConfigs.photos.states.success.buttonText",
        inputType: "text",
      },
      error: {
        status: "error",
        messageKey: "actionableConfigs.photos.states.error.message",
        placeholderKey: "actionableConfigs.photos.states.error.placeholder",
        buttonTextKey: "actionableConfigs.photos.states.error.buttonText",
        inputType: "text",
      },
      moderation: {
        status: "moderation",
        messageKey: "actionableConfigs.photos.states.moderation.message",
        buttonTextKey: "actionableConfigs.photos.states.moderation.buttonText",
        inputType: "text",
        readOnly: true,
        disabled: true,
      },
    },
  },
  {
    key: "postHistoryCountValueIsOk",
    titleKey: "actionableConfigs.googlePost.title",
    bonusAmount: 10,
    highlighted: true,
    states: {
      success: {
        status: "success",
        messageKey: "actionableConfigs.googlePost.states.success.message",
        placeholderKey:
          "actionableConfigs.googlePost.states.success.placeholder",
        buttonTextKey: "actionableConfigs.googlePost.states.success.buttonText",
        inputType: "text",
      },
      error: {
        status: "error",
        messageKey: "actionableConfigs.googlePost.states.error.message",
        placeholderKey: "actionableConfigs.googlePost.states.error.placeholder",
        buttonTextKey: "actionableConfigs.googlePost.states.error.buttonText",
        inputType: "text",
      },
      moderation: {
        status: "moderation",
        messageKey: "actionableConfigs.googlePost.states.moderation.message",
        buttonTextKey:
          "actionableConfigs.googlePost.states.moderation.buttonText",
        inputType: "text",
        readOnly: true,
        disabled: true,
      },
    },
  },
  {
    key: "phone",
    titleKey: "actionableConfigs.phone.title",
    bonusAmount: 0,
    highlighted: false,
    states: {
      displayOnly: {
        status: "info",
        message: "",
        valueKey: "phone",
        inputType: "display",
      },
    },
  },
  {
    key: "websiteValueIsOk",
    titleKey: "actionableConfigs.website.title",
    bonusAmount: 10,
    highlighted: false,
    states: {
      success: {
        status: "success",
        messageKey: "actionableConfigs.website.states.success.message",
        placeholderKey: "actionableConfigs.website.states.success.placeholder",
        buttonTextKey: "actionableConfigs.website.states.success.buttonText",
        inputType: "text",
      },
      error: {
        status: "error",
        messageKey: "actionableConfigs.website.states.error.message",
        placeholderKey: "actionableConfigs.website.states.error.placeholder",
        buttonTextKey: "actionableConfigs.website.states.error.buttonText",
        inputType: "text",
      },
      moderation: {
        status: "moderation",
        messageKey: "actionableConfigs.website.states.moderation.message",
        buttonTextKey: "actionableConfigs.website.states.moderation.buttonText",
        inputType: "text",
        readOnly: true,
        disabled: true,
      },
    },
  },
  {
    key: "descriptionValueIsOk",
    titleKey: "actionableConfigs.description.title",
    bonusAmount: 10,
    highlighted: false,
    states: {
      success: {
        status: "success",
        messageKey: "actionableConfigs.description.states.success.message",
        placeholderKey:
          "actionableConfigs.description.states.success.placeholder",
        buttonTextKey:
          "actionableConfigs.description.states.success.buttonText",
        inputType: "paragraph",
        readOnly: false,
      },
      error: {
        status: "error",
        messageKey: "actionableConfigs.description.states.error.message",
        placeholderKey:
          "actionableConfigs.description.states.error.placeholder",
        buttonTextKey: "actionableConfigs.description.states.error.buttonText",
        inputType: "paragraph",
        readOnly: false,
      },
      moderation: {
        status: "moderation",
        messageKey: "actionableConfigs.description.states.moderation.message",
        buttonTextKey:
          "actionableConfigs.description.states.moderation.buttonText",
        inputType: "paragraph",
        readOnly: true,
        disabled: true,
      },
    },
  },
  {
    key: "address",
    titleKey: "actionableConfigs.address.title",
    bonusAmount: 0,
    highlighted: false,
    states: {
      displayOnly: {
        status: "info",
        message: "",
        valueKey: "address",
        inputType: "display",
      },
    },
  },
];

const BusinessProfilesDetails = ({ t }) => {
  //Modal
  const [isOpen, setIsOpen] = useState(false);
  const [isFinishOpen, setFinishIsOpen] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  //Modal when redirect
  const history = useHistory();
  const [nextLocation, setNextLocation] = useState(null);

  const location = useLocation();

  const { id, socialLocationId } = useParams();
  //catch id from path

  const [summary, setSummary] = useState({});
  const [isOpenn, setIsOpenn] = useState(false);
  
  
  const translatedConfigs = actionableConfigs.map((config) =>
    translateConfig(config, t)
  );
  translatedConfigs[0].bonusAmount = summary.primaryCategoryMatched === false && summary.secondaryCategoryMatched === false ? 55 : (summary.primaryCategoryMatched === false) ? 30 : 25;

  useEffect(() => {
    if (id) {
      getGoogleBusinessProfile(id)
        .then((res) => {
          if (res.data.data) {
            setSummary(res.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching Google Business Profile:", error);
        });
    }
  }, [id]);

  // useEffect(() => {
  //   console.log("Total points changed, now its: ", totalPoints);
  // }, [totalPoints])

  //Modal when redirect
  useEffect(() => {
    if (totalPoints <= 0) {
      return;
    }

    const unblock = history.block((location) => {
      setNextLocation(location);
      setFinishIsOpen(true);
      return false;
    });

    return () => {
      if (unblock) unblock();
    };
  }, [history, location, totalPoints]);

  const modalFinishClose = () => {
    setFinishIsOpen(false);
    if (nextLocation) {
      const locationToNavigate = nextLocation;
      const unblock = history.block(() => {});
      setNextLocation(null);
      history.push(locationToNavigate.pathname);
      unblock();
    }
  };

  return (
    <div className="gbp-details-container">
      <div className="grid-container">
        <div className="title-text">
          <img
            src="/static/img/gbp-icon.svg"
            alt="Icon"
            width="44"
            height="44"
          />
          &nbsp;&nbsp;
          <span
            style={{
              fontFamily: "Open Sans",
              fontSize: "28px",
              color: "1A1A1A",
              fontWeight: "bold",
            }}
          >
            {summary.name}
          </span>
          <div className="mt-0">
            <div className="verification-container">
              <div className="verification-left">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.65619 0.945559C6.84902 0.762939 7.15098 0.762939 7.34381 0.945559C8.68832 2.21887 10.5025 2.99909 12.5 2.99909C12.5318 2.99909 12.5635 2.9989 12.5952 2.9985C12.8134 2.99579 13.0082 3.13496 13.0764 3.3423C13.3514 4.17881 13.5 5.07211 13.5 5.99912C13.5 9.96047 10.7906 13.2877 7.12463 14.2313C7.04288 14.2523 6.95713 14.2523 6.87537 14.2313C3.20937 13.2877 0.5 9.96047 0.5 5.99912C0.5 5.07211 0.648582 4.17881 0.92361 3.3423C0.99178 3.13496 1.18656 2.99579 1.40481 2.9985C1.43649 2.9989 1.46822 2.99909 1.5 2.99909C3.4975 2.99909 5.31168 2.21887 6.65619 0.945559ZM7 4.99908C7.27614 4.99908 7.5 5.22294 7.5 5.49908V7.99908C7.5 8.27523 7.27614 8.49908 7 8.49908C6.72386 8.49908 6.5 8.27523 6.5 7.99908V5.49908C6.5 5.22294 6.72386 4.99908 7 4.99908ZM7 9.49908C6.72386 9.49908 6.5 9.72294 6.5 9.99908V10.0041C6.5 10.2802 6.72386 10.5041 7 10.5041H7.005C7.28114 10.5041 7.505 10.2802 7.505 10.0041V9.99908C7.505 9.72294 7.28114 9.49908 7.005 9.49908H7Z"
                    fill={summary.verified ? "#1EB8FF" : "gray"}
                  />
                </svg>
                <span
                  className={
                    "verification-text ml-1 " +
                    (summary.verified ? "text-blue" : "text-gray")
                  }
                >
                  {summary.verified
                    ? t("categories.modal.verified")
                    : t("categories.modal.notVerified")}
                </span>
              </div>

              {!summary.verified && (
                <div className="verification-right">
                  <a
                    href="https://www.youtube.com/watch?tu-video-id"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tutorial-link"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5ZM8.75 11.5C8.75 11.9142 8.41421 12.25 8 12.25C7.58579 12.25 7.25 11.9142 7.25 11.5V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5V11.5ZM8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55228 3.5 9 3.94772 9 4.5C9 5.05228 8.55228 5.5 8 5.5Z"
                        fill="#1EB8FF"
                      />
                    </svg>
                    <span className="tutorial-text">
                      {t("tutorial.button")}
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <ModalFinish
              isOpen={isFinishOpen}
              toggle={modalFinishClose}
              title={t("modalFinish.title")}
              subtitle={t("modalFinish.subtitle")}
              bonus={totalPoints}
            />
            {summary && (
              <>
                {(() => {
                  const { outstanding, scoreInfoLink } =
                    metricsOutstandingConfiguration(summary, t, () =>
                      setIsOpenn(true)
                    );

                  return (
                    <UxMetricOutstandingComponent
                      {...outstanding}
                      scoreInfoLink={scoreInfoLink}
                    />
                  );
                })()}

                <ScoreScreen
                  isOpen={isOpenn}
                  toggle={() => setIsOpenn(!isOpenn)}
                  t={t}
                />
              </>
            )}
          </div>
          {summary && Object.keys(summary).length > 0 ? (
            <RenderActionableCards
              summary={summary}
              actionableConfigs={translatedConfigs}
              setSummary={setSummary}
              categoriesModalOpen={isOpen}
              setCategoriesModalOpen={setIsOpen}
              iconHeader={Icons.iconHeader()}
              setTotalPoints={setTotalPoints}
              totalPoints={totalPoints}
              socialLocationId={socialLocationId}
              t={t}
            />
          ) : (
            <div>{t("noData")}</div>
          )}
        </div>

        <div className="second-column">
          <div className="row row-9">
            <p className="card-description-video w-100">{t("titleTutorial")}</p>
            <div className="card-video">
              <iframe
                width="100%"
                height="357"
                src="https://www.youtube.com/embed/Qx8ElTKfE-g?si=nfr9e32wx-bb4LDY&amp;start=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="">
            <CardList cardsData={cardsData} layout="columns" />
          </div>
        </div>
        <button
          className="save-and-return"
          onClick={() => history.push(`/localseo/gbp`)}
        >
          {t("saveAndReturn")}
        </button>
      </div>
      <PostModalContainer />
      <PhotosModalContainer />
    </div>
  );
};

BusinessProfilesDetails.translations = {
  ...translations,
};

export const BusinessProfilesDetailsContainer = translate(
  BusinessProfilesDetails
);
