import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "botbit-ui-components";
import { translations } from "../../../../../config/translations/GoogleBusinessProfile/GoogleBusinessProfile";
import {
  getPlaceImprovementsSuggestions,
} from "../../../../../api/googleBusinessProfileApi";

const ModalCategories = ({
  isOpen,
  toggle,
  title,
  subtitle,
  t,
  socialLocationId,
  placeData,
  onApply
}) => {
  // const [isChecked, setIsChecked] = useState(false);
  const [suggestedCategories, setSuggestedCategories] = useState({
    primaryCategory: "",
    categories: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const { categories = [] } = placeData || {};
  const primaryCategory = categories[0] || "";
  const secondaryCategories = categories.slice(1) || [];

  useEffect(() => {
    const fetchSuggestedCategories = async () => {
      if (!socialLocationId) return;

      setIsLoading(true);
      try {
        const response = await getPlaceImprovementsSuggestions(
          socialLocationId
        );

        const improvementData =
          response.data.data.categoriesImprovementOpportunity;
        setSuggestedCategories({
          primaryCategory: improvementData.primaryCategory,
          categories: improvementData.categories || [],
        });
      } catch (error) {
        console.error("Error fetching suggested categories:", error);
        setSuggestedCategories({ primaryCategory: "", categories: [] });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuggestedCategories();
  }, [isOpen, socialLocationId]);

  const handlePostImprovements = async () => {
    try {
      await onApply();
      toggle();
    } catch (error) {
      console.error("Error posting improvements:", error);
    }
  };

  const renderSuggestedCategories = () => {
    if (isLoading) {
      return <p>{t('categories.modal.loading')}</p>;
    }

    return (
      <>
        <h3 className="font-bold m-0">{t("categories.modal.title3")}</h3>
        <p className="font-normal">{t("categories.modal.title4")}</p>
        <p className="d-flex-row gap-2 mb-0-important">
          #1{" "}
          <span className="chips-success">
            {suggestedCategories.primaryCategory}
          </span>
        </p>

        <p className="font-normal">{t("categories.modal.title5")}</p>
        {suggestedCategories.categories &&
          suggestedCategories.categories.map((category, index) => (
            <p key={index} className="d-flex-row gap-2 mb-0-important">
              #{index + 2} <span className="chips-success">{category}</span>
            </p>
          ))}
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-categories">
      <ModalHeader toggle={toggle} className="modal-categories-header">
        <div className="font-bold-600 mb-0 mt-0-important">{title}</div>
      </ModalHeader>
      <ModalBody className="modal-pt-0">
        <p className="subtitle-modal mb-0-important mt-2">{subtitle}</p>
        <div className="spacer"></div>
        <div className="flex-row-modal gap-4">
          <div className="w-50 gap-2 d-flex-column bg-current font-bold-600">
            <h3 className="font-bold m-0">{t("categories.modal.title2")}</h3>
            <p className="font-normal">{t("categories.modal.title4")}</p>
            <p>{primaryCategory}</p>
            <p className="font-normal">{t("categories.modal.title5")}</p>
            {secondaryCategories.map((category, index) => (
              <p className="mb-0-important" key={index}>
                {category}
              </p>
            ))}
          </div>
          <div className="w-50 gap-2 d-flex-column bg-suggestions font-bold-600">
            {renderSuggestedCategories()}
          </div>
        </div>
        <div className="mt-40">
          <div>
            <h3>{t("categories.modal.title6")}</h3>
            <div className="d-flex-row gap-2 align-items-center">
              <div className="checkbox-input-example"></div>
              <label className="mb-0 checkbox-label-example">
                {t("categories.modal.checkbox")}
              </label>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="card-button-modal"
          onClick={handlePostImprovements}
        >
          {t("categories.modal.button")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ModalCategories.translations = {
  ...translations,
};

export default ModalCategories;
