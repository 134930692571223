export const translations = {
  analysis: {
    title: {
      es: "Analizamos tus locales",
      en: "We analyze your locations"
    },
    description: {
      es: "En RAY, realizamos un análisis exhaustivo de tus locales de Google para obtener información sobre el estado de los mismos.",
      en: "At RAY, we perform a comprehensive analysis of your Google locations to obtain information about their status."
    }
  },
  scoring: {
    title: {
      es: "Puntuamos tus locales",
      en: "We score your locations"
    },
    description: {
      es: `Una vez completado el análisis, RAY le asigna una "puntuación" a cada uno de tus locales en base a las opotunidades de mejora que fueron identificadas durante dicho análisis.`,
      en: `Once the analysis is complete, RAY assigns a "score" to each of your locations based on the improvement opportunities identified during the analysis.`
    }
  }, 
  classification: {
    title: {
      es: "Clasificamos tus locales",
      en: "We classify your locations"
    },
    description: {
      es: "Después de puntuar tus locales, RAY los clasifica visualmente mediante un sistema de colores para una rápida identificación de su estado.",
      en: "After scoring your locations, RAY classifies them visually using a color system for quick status identification."
    }
  },
  scoreStatus: {
    currentScore: {
      es: "Tu score general actual es: XX/100",
      en: "Your current general score is: XX/100"
    },
    levels: {
      low: {
        es: "Puntaje Bajo: Atención inmediata.",
        en: "Low Score: Immediate attention required."
      },
      medium: {
        es: "Puntaje medio: Oportunidad de mejorar.",
        en: "Medium Score: Room for improvement."
      },
      high: {
        es: "Puntaje Alto: Excelente desempeño.",
        en: "High Score: Excellent performance."
      }
    }
  },
  criteriaSection: {
    title: {
      es: "El score de tus locales se calcula según los siguientes criterios",
      en: "Your locations' score is calculated according to the following criteria"
    },
    criteria: {
      primaryCategory: {
        es: "Si la categoría primaria del local coincide con la sugerida por el reporte.",
        en: "If the location's primary category matches the one suggested by the report."
      },
      secondaryCategories: {
        es: "Si todas las categorías secundarias del local están contenidas en las sugeridas por el reporte, siempre y cuando sean más de 3.",
        en: "If all secondary categories of the location are contained in those suggested by the report, as long as there are more than 3."
      },
      description: {
        es: "Si la descripción del local es mayor a 50 caracteres.",
        en: "If the location's description is longer than 50 characters."
      },
      googlePosts: {
        es: "Si el local tiene 1 o más Google Posts en los últimos 15 días.",
        en: "If the location has 1 or more Google Posts in the last 15 days."
      },
      website: {
        es: "Si el website del local está correcto y/o no pertenece a una red social.",
        en: "If the location's website is correct and/or does not belong to a social network."
      },
      photos: {
        es: "Si el local tiene 3 o más fotos propias publicadas.",
        en: "If the location has 3 or more of its own photos published."
      }
    }
  }
};
