import React, { useState } from 'react';
import SocialLocationPostContainer from '../../../SocialLocationPostContainer';

let setModalOpen;

export const openPostModal = (socialLocationId) => {
  if (setModalOpen) {
    setModalOpen(socialLocationId);
  }
};

const PostModalContainer = () => {
  const [isPostModalOpen, setIsPostModalOpen] = useState();
  setModalOpen = setIsPostModalOpen;

  return (
    <SocialLocationPostContainer
      modal={{
        isOpen: isPostModalOpen !== undefined,
        toggle: () => setIsPostModalOpen(undefined),
      }}
      socialLocationId={isPostModalOpen}
    />
  );
};

export default PostModalContainer;